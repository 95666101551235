@import url('https://fonts.googleapis.com/css2?family=Red+Rose:wght@300..700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* scrollbar */
::-webkit-scrollbar {
  width: 10px;
  
}

::-webkit-scrollbar-thumb {
  background-color: #00BCA3;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #232323;
  border-radius: 10px;
}


